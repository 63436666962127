import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'
import simg7 from '../images/service/1.jpg'
import simg8 from '../images/service/7.jpg'
import simg9 from '../images/service/8.jpg'
import simg10 from '../images/service/9.jpg'
import simg11 from '../images/service/5.jpg'
import simg12 from '../images/service/10.jpg'

import sIcon1 from '../images/icon/carpet.svg'
import sIcon2 from '../images/icon/season.svg'
import sIcon3 from '../images/icon/express-delivery.svg'
import sIcon4 from '../images/icon/cutlery.svg'
import sIcon5 from '../images/icon/shower.svg'
import sIcon6 from '../images/icon/1.svg'
import sIcon7 from '../images/icon/2.svg'
import sIcon8 from '../images/icon/3.svg'
import sIcon9 from '../images/icon/4.svg'
import sIcon10 from '../images/icon/5.svg'
import sIcon11 from '../images/icon/5.svg'
import sIcon12 from '../images/icon/5.svg'


import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'



const Services = [
    // {
    //     Id: '1',
    //     sImg:simg,
    //     sIcon:sIcon1,
    //     sTitle: 'Carpet',
    //     description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    {
        Id: '2',
        sImg:simg2,
        sIcon:sIcon2,
        sTitle: 'Seasonal',
        description:'We are well aware that seasons tend to bring different cleaning needs and standards such as the flu season, and pollen in the spring. We are dedicated to taking all of these changes into consideration when providing our service.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sIcon:sIcon3,
        sTitle: 'Express',
        description:"We understand things don't always go according to plan. that's why we are open to acomodations for a faster cleaning service upon request.",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sIcon:sIcon4,
        sTitle: 'Kitchen',
        description:'We ensure a thorough cleaning of the kitchen area form counters to appliences and expetially floors.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Bathroom',
        description:'We utilize the safest products to enable bathoom use immediately after cleaning. Our methods ensure that even the harshest of stains will be removed making your bathroom look as good as when you first saw it.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sIcon:sIcon6,
        sTitle: 'Windows',
        description:'Along with blinds, we offer window cleaning as long as they are reachable and can be opened. During the spring, this is a useful service for people allergic to pollen.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:simg7,
        sIcon:sIcon7,
        sTitle: 'Floors',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:simg8,
        sIcon:sIcon8,
        sTitle: 'Office',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '9',
        sImg:simg9,
        sIcon:sIcon9,
        sTitle: 'Table',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '10',
        sImg:simg10,
        sIcon:sIcon10,
        sTitle: 'Computer',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '11',
        sImg:simg11,
        sIcon:sIcon11,
        sTitle: 'Bathroom',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '12',
        sImg:simg12,
        sIcon:sIcon12,
        sTitle: 'Chair',
        description:'We are certified company. We provide best cleaning services for you and your company Home services and your Car.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]
export default Services;