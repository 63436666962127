import React from 'react' 
import CountUp from 'react-countup';

const funFact = [
  {
      title: <CountUp end={100} duration={5}/>,
      subTitle: 'Cleaning Jobs Done',
  },
  {
      title: <CountUp end={34} duration={5}/>,
      subTitle: 'Trusted Clients',
  },
  {
      title: <CountUp end={130} duration={5}/>,
      subTitle: 'Positive feedback',
  },
  {
      title: <CountUp end={45} duration={5}/>,
      subTitle: 'return calls',
  },


]


const FunFact = (props) => {
    return(
      <section className={`wpo-fun-fact-section section-padding ${props.fClass}`}>
          <div className="container">
              <div className="row">
                  <div className="col col-xs-12">
                      <div className="wpo-fun-fact-grids clearfix">
                          {funFact.map((funfact, fitem) => (
                                <div className="grid" key={fitem}>
                                    <div className="info">
                                        <h3>{funfact.title}</h3>
                                        <p>{funfact.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
}

export default FunFact;