import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/Hero'
import Scrollbar from '../../components/scrollbar/scrollbar'
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import WorkSection from '../../components/WorkSection/WorkSection';
import Appointment from '../../components/Appointment/Appointment';
import Appointment3 from '../../components/Appointment/Appointment3.jsx';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import BlogSection from '../../components/BlogSection/BlogSection.js';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import Footer from '../../components/footer/Footer.js';


const HomePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero/>
            <ServiceSection/>
            <WorkSection/>
            <Appointment3/>
            {/* <ProjectSection/> */}
            {/* <TeamSection/> */}
            {/* <Testimonial/> */}
            {/* <BlogSection/> */}
            {/* <PartnerSection/> */} 
            <Footer/>  
            <Scrollbar/>  
        </Fragment>  
    )
};
export default HomePage;