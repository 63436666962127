import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'


const Team = [
    {
       Id:'1',
       tImg:timg1,
       name:'Jasmin Garcia',
       title:'CEO and Lead Cleaner',     
    },
    {
       Id:'2',
       tImg:timg2,
       name:'Yessica Garcia',
       title:'Lead Cleaner',     
    },
   //  {
   //     Id:'3',
   //     tImg:timg3,
   //     name:'Alona Mabry',
   //     title:'Cleaner Leader',     
   //  },
   //  {
   //     Id:'4',
   //     tImg:timg4,
   //     name:'Peter Millard',
   //     title:'Cleaner Leader',     
   //  }
    
]

export default Team;