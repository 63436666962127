import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial.jpg'


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

const testimonial = [
    {
        Des: " Jasmin has been truly fantastic and her quality of work are second to none. I have never once been disappointed in her work.",
        Title: 'Erika Jerwick',
        Sub: "Apartment Resident",
    },
    {
        Des: "Jasmin is always thorough and goes the extra mile to make sure everything is clean and tidy.I highly recommend her.",
        Title: 'Laura P',
        Sub: "Home Owner",
    },
    {
        Des: "Yessica and Jasmin do a fanstastic job with cleaning and working quickly and thoroughly to ensure my place was clean and tidy. She went the extra mile, and the final result was beyond my already high expectations.",
        Title: 'Alex K',
        Sub: "House Resident",
    }
]

const Testimonial = () => {
    return (
        <section className="wpo-testimonials-section section-padding">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5">
                        <div className="wpo-section-title">
                            <h2>Our Client Feedback</h2>
                            <p>Here are some words form our clients</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-testimonials-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <div className="wpo-testimonials-img">
                                <img src={ts1} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="wpo-testimonials-text">
                                <div className="quote">
                                    <i className="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                                <div className="wpo-testimonials-slide owl-carousel">
                                    <Slider {...settings}>
                                        {testimonial.map((tesmnl, tsm) => (
                                            <div className="wpo-testimonials-slide-item" key={tsm}>
                                                <p>{tesmnl.Des}</p>
                                                <h5>{tesmnl.Title}</h5>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;