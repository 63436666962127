'use client'
import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import cimg from '../../images/contact.png'

const Appointment3 = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_kqjxgee';
    const templateId = 'template_vnn5ssg';
    const publicKey = 'dH8uyA6qt9tFTbHSO';
    // Create a new object that contains dynamic template params
    const templateParams = {
      user_name: name,
      user_email: email,
      user_subject: subject,
      user_phone: phone,
      to_name: 'Web Wizard',
      message: message,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setSubject('');
        setPhone('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
    }

    return ( 
        <>
            <section className="wpo-contact-section section-padding">
                <div className="container">
                    <div className="wpo-contact-section-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="wpo-contact-form-area">
                                    <div className="wpo-section-title-s2">
                                        <h2>Make An Appointment</h2>
                                    </div>
                                    <form onSubmit={handleSubmit} className="contact-form" >
                                        <div className="row">
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        className="form-control"
                                                        value={name}
                                                        type="name"
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder="Your Name" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        className="form-control"
                                                        value={email}
                                                        type="email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Your Email" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        className="form-control"
                                                        value={phone}
                                                        type="text"
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        placeholder="Your Phone Number" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        className="form-control"
                                                        value={subject}
                                                        type="subject"
                                                        onChange={(e) => setSubject(e.target.value)}
                                                        placeholder="Your Subject" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col fullwidth col-lg-12 ">
                                                <textarea
                                                    className="form-control"
                                                    value={message}
                                                    type="text"
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder="Message">
                                                </textarea>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn"><i className="fa fa-angle-double-right"
                                                    aria-hidden="true"></i> Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="border-style"></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="wpo-contact-img">
                                    <img src={cimg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    ); 
}; 

export default Appointment3;