import React from 'react'
import abimg from '../../images/about.jpg'
import sign from '../../images/signeture.png'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-section-title-s2">
                                    <h2>Over 8+ Years of Experience
                                        In Cleaning Service</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p>My Mother and I founded this company 8 years ago, exemplifying unwavering dedication to delivering 
                                        top-quality service while nurturing our flourishing mother-daughter business partnership.</p>
                                    <p>we've made it our mission to use the best eco-friendly and family-friendly products, ensuring that our clients' homes remain safe and sustainable. 
                                        We always prioritize customer concerns, valuing their trust and feedback as the foundation of our business, 
                                        and our goal is to consistently provide a stellar experience that exceeds their expectations. 
                                        Our commitment to both our clients and the environment has been at the heart of our mother-daughter journey in the cleaning industry.</p>
                                    <div className="signeture">
                                        <h4>Jasmin Garcia</h4>
                                        <p>J's Pristine Cleaning Services, CEO</p>
                                        <span><img src={sign} alt=""/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;